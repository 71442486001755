import React from 'react'
import { withTranslation } from 'react-i18next'

import I18n from '../components/i18n'
import Layout from '../components/layout'
import SEO from '../components/seo'

const ProcessProductionPage = ({ t }) => {
  return (
    <Layout>
      <SEO title={t('process_production.title')} keywords={[`agriculture`, `cultivate`, `japan`, `vietnam`, `pepper`]} />
			<div className="page">
				<div className="container grid-lg">
					<h1 className="page-title" dangerouslySetInnerHTML={{ __html: t('process_production.title') }}></h1>

          <div className="video-responsive">
            <iframe src="process.pdf"></iframe>
          </div>

          <div className="video-responsive">
            <iframe src="definition.pdf"></iframe>
          </div>
        </div>
			</div>
    </Layout>
  )
}

export default withTranslation()(ProcessProductionPage)
